<template>
  <span id="render" :class="review ? 'review-mode' : ''">
    <v-row v-if="!response" align="center" justify="center" class="text-center" fluid>
      <v-progress-circular :size="80" :width="7" color="primary" indeterminate class="fill-height mx-auto mt-8"></v-progress-circular>
    </v-row>
    <v-card v-else variant="flat" class="text-justify">
      <v-form v-if="response" ref="renderForm" v-model="qvalid" @submit.prevent="handleSubmit" @update:model-value="validate">
        <div v-for="(field, fieldIndex) in form.fields" :key="fieldIndex" class="mb-0 item">
          <v-card v-if="!(field.readOnly && review) && response.item[fieldIndex] && response.item[fieldIndex].answer[0]" :class="field.renderType == 'Paragraph' ? '' : 'px-6 py-2'" elevation="0">
            <div v-if="field.renderType != 'Paragraph'" :class="labelClass(field)" style="white-space: pre-wrap" v-html="field.label"></div>
            <div v-if="field.renderType == 'Table'">
              <v-card v-if="response.item[fieldIndex]">
                <v-data-table hide-default-footer :headers="mapHeaders(field.answerOption)" :items="renderTableAnswers(response.item[fieldIndex].answer)">
                  <template #item="{ item }">
                    <tr>
                      <td v-for="header in mapHeaders(field.answerOption)" :key="header.text">
                        <v-text-field v-model="item[header.text]" density="compact" hide-details></v-text-field>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </div>
            <div v-else-if="field.type == 'string' && !field.readOnly" style="max-width: 50%">
              <v-text-field v-if="response.item[fieldIndex]" v-model="response.item[fieldIndex].answer[0].valueString" required variant="outlined" :clearable="!locked" autocomplete="off" hide-details density="compact" :readonly="locked" :rules="[field.required ? rules.required : true]"></v-text-field>
            </div>
            <div v-else-if="field.renderType == 'Dropdown'" style="max-width: 50%">
              <v-select
                v-if="response.item[fieldIndex]"
                v-model="response.item[fieldIndex].answer[0].valueString"
                :items="field.answerOption"
                item-title="label"
                :item-value="response.item[fieldIndex].answer[0].valueString"
                label="Please select..."
                persistent-hint
                single-line
                variant="outlined"
                density="compact"
                hide-details
                :readonly="locked"
                :rules="[field.required ? rules.required : true]"
                :multiple="locked"></v-select>
            </div>
            <div v-if="field.renderType == 'Paragraph'" class="ql-editor">
              <div v-html="field.label"></div>
            </div>
            <div v-else-if="field.type == 'text'">
              <v-textarea v-if="response.item[fieldIndex]" v-model="response.item[fieldIndex].answer[0].valueString" variant="outlined" clearable autocomplete="off" hide-details :readonly="locked" :rules="[field.required ? rules.required : true]" auto-grow></v-textarea>
            </div>
            <div v-else-if="field.renderType == 'Single Choice'">
              <v-radio-group v-if="response.item[fieldIndex]" v-model="response.item[fieldIndex].answer[0].valueString" class="mt-0" hide-details :class="optionClass(field)" :readonly="locked" :rules="[field.required ? rules.required : true]">
                <v-radio v-for="(option, optionIndex) in field.answerOption" :key="`sc-${fieldIndex}-${optionIndex}`" :value="option.label" class="pa-0 mb-1 my-1" :readonly="locked">
                  <template #label>
                    <span class="">
                      {{ option.label }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <div v-if="field.renderType == 'Multiple Choice'">
              <v-container v-for="(option, optionIndex) in field.answerOption" :key="`mc-${fieldIndex}-${optionIndex}`" class="pa-0 mb-1">
                <v-row>
                  <v-col class="py-0">
                    <v-checkbox v-if="response.item[fieldIndex]" v-model="response.item[fieldIndex].answer[0].valueString" hide-details multiple class="mt-0" :readonly="locked" :class="optionClass(field)" :value="option.label" :rules="[field.required ? rules.required : true]" :label="option.label"></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <div v-else-if="field.renderType == 'Button Group'" class="d-flex justify-center pa-4">
              <v-btn-toggle v-model="response.item[fieldIndex].answer[0]" class="pa-0" :readonly="locked" :rules="[field.required ? rules.required : true]">
                <v-btn v-for="(option, optionIndex) in field.answerOption" :key="`sc-${fieldIndex}-${optionIndex}`" class="pa-0 mb-1" :model-value="option.label">
                  {{ option.label }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </v-card>
        </div>
        <div v-if="!review && (intake || task)" align="center" class="mt-6">
          <v-btn v-if="intake" variant="text" size="large" rounded class="mr-3" @click="previousStep">Previous</v-btn>
          <v-btn id="reasonContinue" size="large" color="primary" rounded required :disabled="!qvalid || locked" :loading="processing" @click="saveResponses">
            {{ intake ? "Continue" : "Submit" }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </span>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { POPULATE_QUESTIONNAIRE, CREATE_QUESTIONNAIRERESPONSE, UPDATE_QUESTIONNAIRERESPONSE } from "../../store/patients/mutation-types";
export default {
  name: "RenderForm",
  props: {
    intake: Boolean,
    review: Boolean,
    task: Boolean,
    populateForm: Boolean,
    responseID: {
      type: String,
      default: null,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  emits: ["is-form-valid", "next-step", "previous-step"],
  data: () => ({
    qvalid: false,
    populated: false,
    rules: {
      required: value => {
        if (Array.isArray(value) && value.length == 0) return "Required";
        return !!value || "Required";
      },
    },
  }),
  computed: {
    ...mapState("patients", ["currentPatient", "responses", "processing"]),
    ...mapState("register", { regPatient: "patient" }),
    ...mapState("auth", ["user"]),
    patientID() {
      //TODO: clean this up, there should be one place to look for the patient object
      return this.regPatient?.id ? this.regPatient.id : this.patient?.id ? this.patient.id : this.currentPatient?.id ? this.currentPatient.id : null;
    },
    locked() {
      return this.review || this.processing;
    },
    response() {
      var resp;
      if (this.responseID) {
        resp = { ...this.responses?.find(r => r.id === this.responseID?.replace("QuestionnaireResponse/", "")) } ?? { item: [] };
      } else {
        resp = { ...this.responses?.find(r => r.questionnaire === `Questionnaire/${this.form?.questionnaireID}`) };
      }
      //make sure each item has an answer
      if (resp && resp.item) {
        resp.item = this.form.fields.map((field, index) => {
          if (!resp.item[index]) {
            return {
              linkId: field.linkId,
              answer: [{ valueString: "" }],
            };
          }
          resp.item[index].answer = resp.item[index].answer ?? [{ valueString: "" }];
          return resp.item[index];
        });
      }
      return { item: [], ...resp };
    },
  },
  watch: {
    responseID() {
      if (this.populateForm) this.populate();
    },
    regPatient() {
      if (this.populateForm) this.populate();
    },
    patient() {
      if (this.populateForm) this.populate();
    },
  },
  async mounted() {
    if (this.populateForm) await this.populate();
  },
  methods: {
    ...mapActions("patients", [CREATE_QUESTIONNAIRERESPONSE, POPULATE_QUESTIONNAIRE, UPDATE_QUESTIONNAIRERESPONSE]),
    async saveResponses() {
      var response = { ...this.response };
      response.subject = {
        reference: `Patient/${this.patientID}`,
        type: "Patient",
      };

      if (this.patientID) {
        if (!this.response || !this.response.id) {
          await this.CREATE_QUESTIONNAIRERESPONSE(response);
        } else {
          response.status = "completed";
          await this.UPDATE_QUESTIONNAIRERESPONSE(response);
        }
      }
      this.nextStep();
    },
    handleSubmit() {
      if (this.qvalid) this.saveResponses();
    },
    nextStep() {
      this.$emit("next-step");
    },
    async previousStep() {
      this.$emit("previous-step");
    },
    labelClass(field) {
      if (this.review) return "text-subtitle-1";
      return field.renderType != "Header" ? "text-h6" : "text-h5";
    },
    optionClass(field) {
      return "text-caption";
    },
    mapHeaders(options) {
      if (!options) return;
      return options.map(o => {
        return { title: o.label, value: o.label };
      });
    },
    renderTableAnswers(answers) {
      return answers.map(a => a.valueString);
    },
    async populate() {
      if (!this.populated) {
        await this.POPULATE_QUESTIONNAIRE({
          patientID: this.patientID,
          form: this.form,
        });
        this.populated = true;
      } else {
        console.log("skipping form population...");
      }
    },
    validate() {
      this.$emit("is-form-valid", this.qvalid);
    },
  },
};
</script>
<style>
#render {
  display: block;
  height: 100%;
}
</style>

<template>
  <v-card v-if="editLocation" min-width="350px">
    <v-card-title class="card-title">
      <span class="text-h6" v-text="value.id ? 'Edit Location' : 'Add Location'"></span>
      <v-btn variant="text" class="mr-2 float-right" icon @click="cancelEdit">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container style="min-height: 400px; overflow: auto; width: 1000px" fluid>
        <v-form ref="form" v-model="valid" autocomplete="off" :disabled="processing">
          <v-row>
            <v-col>
              <v-text-field v-model="editLocation.display" class="mb-5" variant="outlined" hide-details label="Name" auto-grow @update:model-value="handleDisplay($event)"></v-text-field>
              <v-text-field v-model="editLocation.address.line[0]" class="mb-5" variant="outlined" hide-details label="Address" auto-grow @update:model-value="handleAddress($event)"></v-text-field>
              <v-text-field v-model="editLocation.address.city" class="mb-5" variant="outlined" hide-details label="City" auto-grow @update:model-value="handleCity($event)"></v-text-field>
              <v-row>
                <v-col>
                  <v-text-field v-model="editLocation.address.state" class="mb-5" variant="outlined" hide-details label="State" @update:model-value="handleState($event)"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="editLocation.address.postalCode" class="mb-5" variant="outlined" hide-details label="Postal Code" @update:model-value="handlePostalCode($event)"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card flat>
                <v-card-title class="card-title">
                  Communication Methods
                  <v-btn v-if="!showNewTelecom" class="float-right" size="small" variant="flat" color="primary" @click.stop="showNewTelecom = !showNewTelecom">Add</v-btn>
                  <v-btn v-else size="small" variant="outlined" class="float-right" color="grey" @click.stop="showNewTelecom = !showNewTelecom">Cancel</v-btn>
                </v-card-title>
                <v-card-text class="pa-0">
                  <v-list>
                    <v-list-item v-if="showNewTelecom">
                      <v-select v-model="editTelecom.system" density="compact" :items="systems" item-title="display" single-line item-value="value" label="Type" variant="outlined" hide-details class="mx-2"></v-select>
                      <v-text-field v-model="editTelecom.value" density="compact" variant="outlined" hide-details class="mx-2" single-line label="Value"></v-text-field>
                      <v-list-item-action>
                        <v-btn size="small" variant="flat" color="primary" :disabled="!editTelecom.value || !editTelecom.system" @click.stop="addTelecom()">Add</v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-else-if="editLocation.telecom.length == 0">
                      <span class="text-grey">No Entries</span>
                    </v-list-item>
                    <v-list-item v-for="(telecom, i) in editLocation.telecom" :key="telecom.value + '_' + i">
                      <template #prepend>
                        <v-icon class="mr-2" size="small">
                          {{ getIcon(telecom.system) }}
                        </v-icon>
                      </template>
                      <div>
                        {{ telecom.value }}
                      </div>
                      <v-list-item-action>
                        <v-icon class="mr-2" @click="removeTelecom(i)">mdi-close</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="text" @click="cancelEdit()">Cancel</v-btn>
      <v-btn color="primary" variant="flat" @click.stop="saveLocation()">Submit</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  components: {},
  inheritAttrs: false,
  props: ["value"],
  data: () => ({
    editDialog: false,
    showNewTelecom: false,
    editLocation: { address: { line: [""] } },
    processing: false,
    valid: true,
    editTelecom: { system: "phone", value: "" },
    systems: [
      { value: "phone", display: "Phone", icon: "mdi-phone" },
      { value: "fax", display: "Fax", icon: "mdi-fax" },
      { value: "email", display: "E-Mail", icon: "mdi-email" },
    ],
  }),
  watch: {
    value(newValue) {
      this.editLocation = newValue;
    },
  },
  created() {
    this.editLocation = { ...this.value };
  },
  methods: {
    handleDisplay($event) {
      this.editLocation.display = $event;
      this.$emit("input", this.editLocation);
    },
    handleAddress($event) {
      this.editLocation.address.line[0] = $event;
      this.$emit("input", this.editLocation);
    },
    handleCity($event) {
      this.editLocation.address.city = $event;
      this.$emit("input", this.editLocation);
    },
    handleState($event) {
      this.editLocation.address.state = $event;
      this.$emit("input", this.editLocation);
    },
    handlePostalCode($event) {
      this.editLocation.address.postalCode = $event;
      this.$emit("input", this.editLocation);
    },
    addTelecom() {
      this.editLocation.telecom.push({ ...this.editTelecom });
      this.editTelecom = { system: "phone", value: "" };
      this.showNewTelecom = false;
    },
    removeTelecom(index) {
      this.editLocation.telecom.splice(index, 1);
    },
    cancelEdit() {
      this.$emit("cancel");
    },
    saveLocation() {
      this.$emit("save", this.editLocation);
    },
    getIcon(system) {
      return this.systems.find(s => s.value === system).icon;
    },
  },
};
</script>

<template>
  <v-avatar v-if="user" v-bind="$attrs" :color="avatarColor.hex">
    <failover-image v-if="imageUrl" alt="Avatar" :src="imageUrl" v-bind="$attrs" />
    <v-icon v-else-if="user.icon" :icon="user.icon"></v-icon>
    <span v-else-if="user && renderInitials" class="font-weight-bold text-uppercase text-no-wrap initials" v-bind="$attrs" :class="{ 'initials-small': size < 30 }" :style="calculatedStyle" v-text="renderInitials"></span>
    <v-icon v-else class="text-white">mdi-account</v-icon>
  </v-avatar>
</template>
<script>
import { colorFromString } from "../../utils/helpers";
import { renderName } from "../../utils/fhir-helpers.js";

export default {
  name: "Avatar",
  inheritAttrs: true,
  props: {
    user: {
      type: [Object, String],
      default: () => {},
    },
  },
  data: () => ({}),
  computed: {
    avatarColor() {
      if (!this.user) return {};
      return this.imageUrl ? {} : colorFromString(this.renderInitials);
    },
    renderInitials() {
      const name = this.user.name ?? this.user.display ?? this.user.title ?? this.user.text ?? this.user;
      let fullName = typeof name === "string" ? name?.split(" ") : renderName(name)?.split(" "),
        firstName = fullName?.[0]?.replace(/[^a-zA-Z\s]/g, ""),
        lastName = fullName?.[fullName.length - 1]?.replace(/[^a-zA-Z\s]/g, "");

      if (fullName?.length > 1) {
        return (firstName?.charAt(0) ?? "") + (lastName?.charAt(0) ?? "");
      } else {
        return firstName?.charAt(0) ?? "";
      }
    },
    imageUrl() {
      if (!this.user) return;
      if (this.user.profileImage === false) return;
      if (Array.isArray(this.user.photo)) {
        return this.user.photo[0].url;
      }
      if (this.user.icon?.startsWith("mdi-")) return;
      return this.user.icon ?? this.user.photo ?? this.user.profileImage ?? this.user.logo;
    },

    size() {
      return this.$attrs.size?.replace("px", "");
    },
    calculatedStyle() {
      if (!this.size || this.size > 20) return {};
      let fontSize = this.size / 2;
      return {
        "font-size": fontSize + "px",
      };
    },
  },
  created() {},
};
</script>
<style scoped>
.initials {
  font-size: 18px;
}

.initials-small {
  font-size: 15px;
}
</style>

import { defineStore } from "pinia";
import * as fhirHelper from "../../utils/fhir-helpers";
import * as api from "../../utils/api.js";

export const useTasks = defineStore("tasks", {
  state: () => ({
    tasks: [],
    assignedProviders: [],
    loading: false,
    ownerStats: [],
    processing: false,
    error: null,
    taskTypeValueSet: null,
    taskTypes: [],
    fieldValueSet: null,
    defaultCustomFields: {
      resourceType: "ValueSet",
      identifier: [
        {
          system: "carelaunch",
          value: "task-field-value-set",
        },
      ],
      name: "Task Field Value Set",
      status: "active",
      expansion: {
        timestamp: new Date().toISOString(),
        contains: [],
      },
    },
    defaultTaskTypes: [
      { value: "Task", text: "Task", icon: "mdi-checkbox-marked-circle-outline" },
      { value: "Second Opinion", text: "Second Opinion", icon: "mdi-account-multiple-check" },
      { value: "Schedule Opinion Delivery", text: "Schedule Opinion Delivery", icon: "mdi-calendar-clock" },
      { value: "Final Opinion Delivery", text: "Final Opinion Delivery", icon: "mdi-gift" },
      { value: "Record Collection", text: "Record Collection", icon: "mdi-file-multiple" },
      { value: "Record Approval", text: "Record Approval", icon: "mdi-file-check" },
      { value: "Physician Acceptance", text: "Physician Acceptance", icon: "mdi-account-check" },
      { value: "Schedule Appointment", text: "Schedule Appointment", icon: "mdi-calendar-clock" },
      { value: "Video Visit", text: "Video Visit", icon: "mdi-video" },
      { value: "Report Completion", text: "Report Completion", icon: "mdi-file-check" },
      { value: "Report Delivery", text: "Report Delivery", icon: "mdi-gift" },
      {
        value: "Case Review",
        text: "Case Review",
        icon: "mdi-account-multiple-check",
        businessStatuses: [
          {
            coding: [
              {
                system: "CaseReviewStatus",
                code: "Awaiting Review",
              },
            ],
            text: "Awaiting Review",
          },
          {
            coding: [
              {
                system: "CaseReviewStatus",
                code: "Requires Additional Review",
              },
            ],
            text: "Additional Review",
          },
          {
            coding: [
              {
                system: "CaseReviewStatus",
                code: "Complete",
              },
            ],
            text: "Complete",
          },
        ],
      },
      {
        value: "Provider Assignment",
        text: "Provider Assignment",
        icon: "mdi-account-check",
        businessStatuses: [
          {
            coding: [
              {
                system: "ProviderAssignmentStatus",
                code: "Awaiting Assignment",
              },
            ],
            text: "Awaiting Assignment",
          },
          {
            coding: [
              {
                system: "ProviderAssignmentStatus",
                code: "Pending Acceptance",
              },
            ],
            text: "Pending Acceptance",
          },
          {
            coding: [
              {
                system: "ProviderAssignmentStatus",
                code: "Complete",
              },
            ],
            text: "Complete",
          },
        ],
      },
      { value: "Questionnaire", text: "Questionnaire", icon: "mdi-file-question" },
      { value: "Zendesk", text: "Zendesk", icon: "mdi-help-circle" },
      { value: "Other", text: "Other", icon: "mdi-dots-horizontal" },
    ],
  }),
  getters: {
    getResourceByCode: state => (name, code) => {
      return state.resources[name]?.find(r => r.code == code);
    },
    getCustomFields: state => {
      return (
        state.fieldValueSet?.expansion?.contains.map(field => {
          return {
            value: field.code,
            title: field.display,
            type: field.system
          };
        }
        ) || []
      );
    }
  },
  actions: {
    async loadTasks(options) {
      if (!options) return;
      this.error = null;
      this.loading = true;

      const params = new URLSearchParams();
      params.append("_sort", "-authoredOn");
      params.append("_include", "Task:owner");
      params.append("_revinclude", "Task:part-of");
      Object.entries(options).forEach(([key, value]) => {
        if (value) {
          if (Array.isArray(value)) {
            value.forEach(v => params.append(key, v));
          } else {
            params.append(key, value);
          }
        }
      });
      if (!options.subject && !options.owner) {
        this.loading = false;
        return;
      }

      try {
        let response = await api.get(`task?${params.toString()}`, true);
        // Extract new providers and tasks from the response
        const newProviders = response?.data?.entry?.filter(e => e.resource.resourceType === "Practitioner")?.map(e => e.resource) || [];
        const newTasks = response?.data?.entry?.filter(e => e.resource.resourceType === "Task")?.map(e => e.resource) || [];

        // Merge with existing providers and tasks, ensuring uniqueness
        this.assignedProviders = [...new Set([...this.assignedProviders, ...newProviders])];
        const taskIds = this.tasks.reduce((acc, task) => { acc[task.id] = true; return acc; }, {});
        this.tasks = [...this.tasks, ...newTasks.filter(task => !taskIds[task.id])];
        this.loading = false;
        return response?.data;
      } catch (ex) {
        this.error = ex;
      } finally {
        this.loading = false;
      }
    },
    async loadTaskTypes() {
      let response = await api.get(`ValueSet?identifier=task-types`);
      if (response?.data?.entry?.length > 0) {
        this.taskTypeValueSet = response.data.entry[0].resource;
        this.taskTypes = this.taskTypeValueSet.compose.include.map(i => {
          return { value: i.concept[0].code, text: i.concept[0].display };
        });
      } else {
        this.taskTypes = [...this.defaultTaskTypes];
        this.taskTypeValueSet = {
          resourceType: "ValueSet",
          status: "active",
          identifier: [{ system: "carelaunch", value: "task-types" }],
          compose: {
            include: this.taskTypes.map(t => {
              return {
                system: "http://hl7.org/fhir/task-code",
                concept: [
                  {
                    code: t.value,
                    display: t.text,
                  },
                ],
              };
            }),
          },
        };
        await this.saveTaskTypes();
      }
    },
    async saveTaskTypes() {
      if (!this.taskTypeValueSet) return;
      this.loading = true;

      if (!this.taskTypeValueSet.id) {
        let response = await api.post("ValueSet", this.taskTypeValueSet);
        if (response?.data?.id) this.taskTypeValueSet = response.data;
      } else {
        await api.client.put(`ValueSet/${this.taskTypeValueSet.id}`, this.taskTypeValueSet);
      }
      this.loading = false;
    },
    async addTaskType(taskType) {
      if (!this.taskTypes.find(t => t.value === taskType.value)) {
        this.taskTypes.push(taskType);
        this.taskTypeValueSet.compose.include.push({
          system: "http://hl7.org/fhir/task-code",
          concept: [
            {
              code: taskType.value,
              display: taskType.text,
            },
          ],
        });
        await this.saveTaskTypes();
      }
    },
    async deleteTaskType(taskType) {
      this.taskTypes = this.taskTypes.filter(t => t.value !== taskType.value);
      this.taskTypeValueSet.compose.include = this.taskTypeValueSet.compose.include.filter(i => i.concept[0].code !== taskType.value);
      await this.saveTaskTypes();
    },
    //delete
    async deleteTask(task) {
      this.processing = true;
      let response = null;
      try {
        response = await api.deleteResource("Task/" + task.id);
        // Update the state by removing the deleted task
        if (task.id)
          this.tasks = this.tasks.filter(t => t.id !== task.id);
      } catch (error) {
        console.error("Failed to delete task:", error);
        this.error = error;
      }
      this.processing = false;
      return response;
    },
    async createTask(task) {
      try {
        await api.post("Task", task);
        await this.loadTasks(task.for.reference);
      } catch (error) {
        console.error("Failed to create task:", error);
        this.error = error;
      }
    },
    async updateTask(task) {
      try {
        if (task.id) {
          task = await api.put("Task/" + task.id, task);
        } else {
          task = await api.post("Task", task);
        }
        task = fhirHelper.mapTaskToVM(task);
        task.updating = false;
        //update tasks
        let index = this.tasks.findIndex(t => t.id === task.id);
        if (index === -1) {
          this.tasks.push(task);
        } else {
          this.tasks[index] = task;
        }

        return task;

      } catch (error) {
        console.error("Failed to update task:", error);
        this.error = error;
      }
    },
    async loadTask(id) {
      try {
        let response = await api.get("Task/" + id);
        return response?.data;
      } catch (error) {
        console.error("Failed to load task:", error);
        this.error = error;
      }
    },
    async loadCustomFields() {
      let response = await api.get(`ValueSet?identifier=carelaunch|task-field-value-set`);
      if (response?.data?.entry?.length > 0) {
        this.fieldValueSet = response.data.entry[0].resource;
        this.fieldValueSet.expansion.contains = this.fieldValueSet.expansion.contains ?? [];
      } else {
        this.fieldValueSet = { ...this.defaultCustomFields };
      }
    },
    async resetTasks() {
      this.tasks = [];
    },
    async saveFields() {
      if (!this.fieldValueSet) return;
      this.loading = true;
      if (!this.fieldValueSet.id) {
        let response = await post("ValueSet", this.fieldValueSet);
        if (response?.data?.id) this.fieldValueSet = response.data;
      } else {
        await api.client.put(`ValueSet/${this.fieldValueSet.id}`, this.fieldValueSet);
      }
      this.loading = false;
    },
    async loadOwnerStats() {
      const dat = await api.get("task/$ownerStats");
      this.ownerStats = dat?.data;
    },
  },
});

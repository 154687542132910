import * as types from "./mutation-types";
import { getUserAttributes, getUserGroups, getUserPool, mapUser } from "../../utils/auth-helper.js";

export const actions = {
    [types.AUTH_LOGOUT]: async ({ commit }, skipRedirect) => {
        commit(types.AUTH_LOGOUT);
        localStorage.clear();
        sessionStorage.clear();


        commit("appointments/RESET_STATE", null, { root: true });
        commit("patients/PATIENTS_RESET", null, { root: true });
        commit("practitioners/RESET", null, { root: true });
        commit("messaging/RESET", null, { root: true });
        // commit("register/RESET_STATE", null, { root: true });
        commit("tasks/RESET_STATE", null, { root: true });

        // Sentry.configureScope(scope => scope.setUser(null));

        await post("admin/logout")

        if (!skipRedirect) window.location.href = "/userlogin?r=" + Math.random();
    },
    [types.SET_USER]: async ({ commit, dispatch }, cognitoUser) => {
        commit(types.SET_USER, cognitoUser);

        const attrs = await getUserAttributes(cognitoUser);
        cognitoUser.attributes = attrs;
        let user = mapUser(cognitoUser);
        // Sentry.setUser({ email: user.userID });
        user.groups = await getUserGroups(cognitoUser);
        localStorage.setItem("user", JSON.stringify(user));

        commit(types.SET_USER_SUCCESS, user);
    },
    [types.UPDATE_SETTINGS]: async ({ commit, state, dispatch }, settings) => {
        commit(types.UPDATE_SETTINGS, settings);
        let user = state.user;
        user.settings = settings;
        commit(types.UPDATE_SETTINGS_SUCCESS, user);
        localStorage.setItem("user", JSON.stringify(user));
    },
};

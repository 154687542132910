import { defineStore } from 'pinia'
import * as api from "../../utils/api.js";

export const useFhir = defineStore('fhir', {
    state: () => ({
        resources: {
            PlanDefinition: [],
            Tasks: [],
            ValueSet: [],
            FieldValueSet: [],
        },
        searchResults: null,
        resourceCounts: [],
        topObservationCodes: [],
        customPatientFields: [],
        defaultCustomFields: {
            resourceType: "ValueSet",
            identifier: [
                {
                    system: "carelaunch",
                    value: "patient-field-value-set",
                },
            ],
            name: "Patient Field Value Set",
            status: "active",
            expansion: {
                timestamp: new Date().toISOString(),
                contains: [],
            },
        },
        customOrganizationFields: [],
        defaultOrganizationCustomFields: {
            resourceType: "ValueSet",
            identifier: [
                {
                    system: "carelaunch",
                    value: "organization-field-value-set",
                },
            ],
            name: "Organization Field Value Set",
            status: "active",
            expansion: {
                timestamp: new Date().toISOString(),
                contains: [],
            },
        },
    }),
    getters: {
        activePlans(state) {
            return state.resources.PlanDefinition?.filter(p => p.status == "active").sort((a, b) => a.title.localeCompare(b.title)) ?? [];
        },
        defaultPlan(state) {
            return state.resources.PlanDefinition.filter(p => p.usage == "Default")?.[0] ?? state.resources.PlanDefinition.filter(p => p.status == "active")?.[0] ?? {
                action: [
                    {
                        title: "Action",
                        condition: [
                            {
                                kind: "applicability",
                                expression: {
                                    language: "text/cql",
                                    expression: "Task.businessStatus = `ready`"
                                }
                            }
                        ]
                    }]
            };
        },
        getPlansByType: (state) => (type) => {
            return state.resources.PlanDefinition.filter(p => p.status == "active" && p.type?.coding?.[0]?.code == type);
        },
        getResourceById: (state) => (name, id) => {
            return state.resources[name]?.find(r => r.id == id);
        },
        getResourceByIdentifier: (state) => (name, identifier) => {
            return state.resources[name]?.find(r => r.identifier?.[0]?.value == identifier);
        },
        getResourceByCode: (state) => (name, code) => {
            return state.resources[name]?.find(r => r.code == code);
        },
        getCustomPatientFields: state => {
            return (
                state.customPatientFields?.expansion?.contains.map(field => {
                    return {
                        value: field.code,
                        title: field.display,
                        type: field.system
                    };
                }
                ) || []
            );
        }
    },
    actions: {
        async loadResourceCounts(noCache) {
            const resourceCountResponse = await api.search("admin/resourceCounts", {}, noCache);
            this.resourceCounts = resourceCountResponse?.data?.metrics ?? [];
            this.resourceCounts?.sort((a, b) => b.count - a.count);
        },
        async searchResource(name, query) {
            let response = await api.search(name, query ?? {});
            this.searchResults = response?.data ?? [];
            return response?.data;
        },
        async loadResources(name) {
            let response = await api.get(name);
            this.resources[name] = response?.data?.entry?.map(e => e.resource) ?? [];
        },
        async updateResource(name, resource) {
            let response = await api.put(name, resource);
            return response?.data;
        },
        async createResource(name, resource) {
            let response = await api.post(name, resource);
            return response?.data;
        },
        async deleteResource(name, id) {
            let response = await api.deleteResource(`${name}/${id}`);
            //remove from store
            this.resources[name] = this.resources[name].filter(r => r.id != id);
            return response?.data;
        },
        async fetchTopObservationCodes() {
            let response = await api.get("admin/report/observation-counts-by-code/json");
            this.topObservationCodes = response?.data?.rowData
        },
        async loadCustomPatientFields() {
            let response = await api.get(`ValueSet?identifier=carelaunch|patient-field-value-set`);
            if (response?.data?.entry?.length > 0) {
                this.customPatientFields = response.data.entry[0].resource;
                this.customPatientFields.expansion.contains = this.customPatientFields.expansion.contains ?? [];
            } else {
                this.customPatientFields = { ...this.defaultOrganizationCustomFields };
            }
        },
        async saveCustomPatientFields() {
            if (!this.customPatientFields) return;
            this.loading = true;
            if (!this.customPatientFields.id) {
                let response = await post("ValueSet", this.customPatientFields);
                if (response?.data?.id) this.customPatientFields = response.data;
            } else {
                await api.client.put(`ValueSet/${this.customPatientFields.id}`, this.customPatientFields);
            }
            this.loading = false;
        },
        async loadCustomOrganizationFields() {
            let response = await api.get(`ValueSet?identifier=carelaunch|organization-field-value-set`);
            if (response?.data?.entry?.length > 0) {
                this.customOrganizationFields = response.data.entry[0].resource;
                this.customOrganizationFields.expansion.contains = this.customOrganizationFields.expansion.contains ?? [];
            } else {
                this.customOrganizationFields = { ...this.defaultOrganizationCustomFields };
            }
        },
        async saveCustomOrganizationFields() {
            if (!this.customOrganizationFields) return;
            this.loading = true;
            if (!this.customOrganizationFields.id) {
                let response = await post("ValueSet", this.customOrganizationFields);
                if (response?.data?.id) this.customOrganizationFields = response.data;
            } else {
                await api.client.put(`ValueSet/${this.customOrganizationFields.id}`, this.customOrganizationFields);
            }
            this.loading = false;
        },
    },
})